<template>
  <div class="category-list">
    <div class="breadcrumb-con">
      <p>您的位置：</p>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="list in from"
          :to="{ path: list.href }"
          :key="list.path"
          >{{ list.name }}</el-breadcrumb-item
        >
        <el-breadcrumb-item
          @click.native="breadcrumbClick(list.rank)"
          v-for="list in breadList"
          :key="list.categoryId"
          >{{ list.categoryName }}</el-breadcrumb-item
        >
      </el-breadcrumb>
      <svg-icon icon-class="ic-close" @click="goBack" />
    </div>
    <div class="category-selection">
      <div class="brand">
        <div class="left">
          <h3>品牌</h3>
        </div>
        <div class="right">
          <ul ref="Ul">
            <li
              v-for="(list, index) in Brand"
              :key="index"
              @click="getBrandGoods(list.id, index)"
              :title="list.brandName"
              :class="[
                onBrand === index ? 'liOn' : '',
                liWidth ? 'liwidth' : '',
              ]"
            >
              {{ list.brandName }}
            </li>
          </ul>
          <div class="search-box" v-if="Brand.length > 18">
            <button class="more btn" @click="more">
              <svg-icon
                icon-class="ic-arrow-a"
                :class="[isMore ? 'close' : 'open']"
              />更多
            </button>
          </div>
        </div>
      </div>
      <div class="time">
        <div class="left">
          <h3>最新上架</h3>
        </div>
        <div class="right">
          <ul>
            <li
              v-for="(list, index) in time"
              :key="index"
              @click="day(list)"
              :class="[
                onTime === time[index] ? 'liOn' : '',
                liWidth ? 'liwidth' : '',
              ]"
            >
              {{ list }}天以内
            </li>
          </ul>
        </div>
      </div>
      <div class="time">
        <div class="left">
          <h3>产地</h3>
        </div>
        <div class="right">
          <ul>
            <li
              :class="noLimit ? 'liOn' : ''"
              @click="[(noLimit = true), (onCity = '')]"
            >
              不限
            </li>
            <li
              v-for="(list, index) in placeOfOrigin"
              :key="index"
              @click="city(list)"
              :class="[
                onCity === placeOfOrigin[index] ? 'liOn' : '',
                liWidth ? 'liwidth' : '',
              ]"
            >
              {{ list }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <goods-list
      :category="category"
      :brandId="BrandId"
      :time="onTime"
      :city="onCity"
    />
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";
import { getclassificationArray } from "@/utils/validate.js";

export default {
  name: "category-list",
  components: { GoodsList },
  data() {
    return {
      time: [7, 30, 90],
      isMore: false,
      liWidth: false,
      Brand: "",
      BrandId: "",
      onBrand: -1,
      onTime: "",
      placeOfOrigin: ["北京", "上海", "广东", "湖南"],
      onCity: "",
      noLimit: true,
      category: [],
      categoryNum: [],
    };
  },
  computed: {
    breadList() {
      let breadList = [];
      let category = JSON.parse(localStorage.getItem("category"));
      this.categoryNum = JSON.parse(this.$route.query.breadcrumb);
      let index = this.categoryNum;
      // console.log(category);
      breadList.push(category[index[0]]);
      let arr = getclassificationArray(category[index[0]]);
      if (index[1] != undefined) {
        breadList.push(breadList[0].subItemList[index[1]]);
        arr = getclassificationArray(breadList[0].subItemList[index[1]]);
      }
      if (index[2] != undefined) {
        breadList.push(
          breadList[0].subItemList[index[1]].subItemList[index[2]]
        );
        arr = getclassificationArray(
          breadList[0].subItemList[index[1]].subItemList[index[2]]
        );
      }
    
      this.getAllBrand(arr);
      this.category = arr;
      return breadList;
    },
    from() {
      let from = [{ name: "首页", href: "/Home" }];
      if (this.$route.meta.name === "全部类目") {
        from.push({
          name: "全部类目",
          href: "/Category/AllCtegories",
        });
      }

      return from;
    },
  },
  created() {},
  methods: {
    //品牌
    async getAllBrand(arr) {
      const data = await this.$API.brand.AllBrand({
        object: {
          brandName: "",
          categoryId: arr,
        },
        pageNum: 0,
        pageSize: 0,
      });
      this.Brand = data.data.data;
      if (this.Brand.length < 19) {
        this.$refs.Ul.style.height = "29px";
      } else {
        this.$refs.Ul.style.height = "58px";
        this.liWidth = true;
      }
    },
    //选择品牌
    getBrandGoods(id, i) {
      if (this.BrandId === id) {
        this.BrandId = "";
        this.onBrand = "";
      } else {
        this.BrandId = id;
        this.onBrand = i;
      }
    },
    //选择时间
    day(day) {
      if (this.onTime === day) {
        this.onTime = "";
      } else {
        this.onTime = day;
      }
    },
    city(city) {
      this.onCity = city;
      // console.log(this.onCity);
      this.noLimit = false;
    },
    //品牌更多按钮展开
    more() {
      let h = this.$refs.Ul.scrollHeight;
      if (this.isMore) {
        this.$refs.Ul.style.height = "58px";
        this.isMore = false;
      } else {
        this.$refs.Ul.style.height = h + "px";
        this.isMore = true;
      }
    },
    breadcrumbClick(i) {
      let oldVal = this.categoryNum;
      if (oldVal.length != i) {
        oldVal.splice(i);
        let newVal = oldVal;
        this.$router.push({
          name: "category-list",
          query: {
            breadcrumb: JSON.stringify(newVal),
          },
        });
      }
    },
    goBack() {
      let oldVal = this.categoryNum;
      if (oldVal.length === 1) {
        let route = this.from[this.from.length - 1].href;
        this.$router.push(route);
      } else {
        oldVal.splice(oldVal.length - 1);
        let newVal = oldVal;
        this.$router.push({
          name: "category-list",
          query: {
            breadcrumb: JSON.stringify(newVal),
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-top: var(--subjectColor) 1px solid;
  .breadcrumb-con {
    width: 1200px;
    height: 55px;
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      color: #999;
    }
    ::v-deep.el-breadcrumb {
      span {
        color: #999;
      }
      .el-breadcrumb__inner {
        font-weight: normal;
      }
      .el-breadcrumb__inner:hover {
        color: var(--subjectColor);
      }
    }
    ::v-deep.el-breadcrumb .el-breadcrumb__item span {
      cursor: pointer;
    }
    ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
      color: #333;
      cursor: default;
    }
    .svg-icon {
      margin-left: 4px;
      font-size: 12px;
      color: #333;
      cursor: pointer;
    }
    .svg-icon:hover {
      color: #000;
    }
  }
  .category-selection {
    width: 1200px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    div:last-child {
      border: none;
    }
    .brand,
    .time,
    .trait {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      .left {
        width: 80px;
        padding: 12px 16px;
        background: #f5f6f8;
        line-height: 16px;
        h3 {
          float: right;
          font-size: 12px;
          font-weight: bold;
          color: #333333;
        }
      }
      .right {
        width: 1118px;
        display: flex;
        padding: 6px 10px;
        justify-content: space-between;

        ul {
          display: flex;
          flex-wrap: wrap;
          // width: 780px;
          overflow: hidden;
          transition: all 0.3s;
          li {
            width: 106px;
            padding: 6px 0;
            margin-right: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            color: #666666;
            line-height: 16px;
            cursor: pointer;
            -moz-user-select: none;
            -ms-user-select: none;
            -webkit-user-select: none;
            user-select: none;
          }
          li:hover {
            color: var(--subjectColor);
          }
          .liOn {
            color: var(--subjectColor);
            font-weight: bold;
          }
          li:nth-child(9n) {
            margin: 0;
          }
          .liwidth {
            width: 106px;
          }
        }
        .search-box {
          display: flex;
          .btn {
            width: 60px;
            height: 28px;
            background: #ffffff;
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-left: 12px;
            cursor: pointer;
            .svg-icon {
              color: #989898;
              font-size: 10px;
              margin-right: 4px;
              transition: all 0.3s;
            }
            .multi-select {
              transform: rotate(-45deg);
            }
            .close {
              transform: rotate(-180deg);
            }
            .open {
              transform: rotate(0);
            }
          }
        }
      }
    }
  }
  .more {
    width: 60px;
    height: 28px;
    background: #ffffff;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    cursor: pointer;
    .svg-icon {
      color: #989898;
      font-size: 10px;
      margin-right: 4px;
    }
  }
}
</style>